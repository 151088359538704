$color-running: #d6bb42;
$color-done: #058810ce;
$color-pending: #3b82f6;
$color-error: #f61111;
$color-dots: rgb(117, 117, 117);

.tenant-creation {
    .color-running {
        color: $color-running !important;
    }
    .color-done {
        color: $color-done !important;
    }
    .color-pending {
        color: $color-pending !important;
    }
    .color-error,
    .color-aborted {
        color: $color-error !important;
    }
    .loading {
        display: inline-block;
        clip-path: inset(0 1ch 0 0);
        animation: l 1s steps(4) infinite;
    }

    @keyframes l {
        to {
            clip-path: inset(0 -1ch 0 0);
        }
    }

    .timeline-steps {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .timeline-steps .timeline-step {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 1rem;
    }

    @media (min-width: 768px) {
        .timeline-steps .timeline-step:not(:last-child):after {
            content: '';
            display: block;
            border-top: 0.25rem dotted $color-dots;
            width: 3.46rem;
            position: absolute;
            left: 7.5rem;
            top: 0.3125rem;
        }
        .timeline-steps .timeline-step:not(:first-child):before {
            content: '';
            display: block;
            border-top: 0.25rem dotted $color-dots;
            width: 3.8125rem;
            position: absolute;
            right: 7.5rem;
            top: 0.3125rem;
        }
    }

    .timeline-steps .timeline-content {
        width: 10rem;
        text-align: center;
    }

    .timeline-steps .timeline-content .inner-circle {
        border-radius: 1.5rem;
        height: 1rem;
        width: 1rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &-running {
            border: 1px dashed $color-running;
            background-color: $color-running;
        }
        &-done {
            background-color: $color-done;
            background-color: $color-done;
        }
        &-pending {
            border: 1px dashed $color-pending;
            background-color: $color-pending;
        }
        &-error,
        &-aborted {
            background-color: $color-error;
            background-color: $color-error;
        }
    }

    .timeline-steps .timeline-content .inner-circle {
        :before {
            content: '';
            display: inline-block;
            height: 3rem;
            width: 3rem;
            min-width: 3rem;
            border-radius: 6.25rem;
            opacity: 0.5;
        }
        &-running:before {
            content: '';
            display: inline-block;
            height: 3rem;
            width: 3rem;
            min-width: 3rem;
            border-radius: 6.25rem;
            opacity: 0.5;
            background-color: $color-running;
        }
        &-done:before {
            content: '';
            display: inline-block;
            height: 3rem;
            width: 3rem;
            min-width: 3rem;
            border-radius: 6.25rem;
            opacity: 0.5;
            background-color: $color-done;
        }
        &-pending:before {
            content: '';
            display: inline-block;
            height: 3rem;
            width: 3rem;
            min-width: 3rem;
            border-radius: 6.25rem;
            opacity: 0.5;
            background-color: $color-pending;
        }
        &-error:before,
        &-aborted:before {
            content: '';
            display: inline-block;
            height: 3rem;
            width: 3rem;
            min-width: 3rem;
            border-radius: 6.25rem;
            opacity: 0.5;
            background-color: $color-error;
        }
    }
}
