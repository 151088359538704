/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


/* CSS to customize traits */
.gjs-trt-trait {
    border-block-width: 1px !important;
    border-block-end: dotted;
}

/* MateriaBoxMembers */
.gjs-trt-trait--materia-box-members .gjs-label-wrp {
    width: 40% !important;
}

/* DateFormatMembers */
.gjs-trt-trait--date-format-members {
    display: block !important;
}

    .gjs-trt-trait--date-format-members .gjs-label-wrp {
        width: 100% !important;
    }

/* Input type checkbox */
.gjs-trt-trait--checkbox {
    /*display: block !important;*/
}

    .gjs-trt-trait--checkbox .gjs-label-wrp {
        width: 100% !important;
    }

/* Input type select */
.gjs-trt-trait--select {
    display: block !important;
}

    .gjs-trt-trait--select .gjs-label-wrp {
        width: 100% !important;
    }

/* Input type number */
.gjs-trt-trait--number {
    display: block !important;
}

    .gjs-trt-trait--number .gjs-label-wrp {
        width: 100% !important;
    }

/* Input type text */
.gjs-trt-trait--text {
    display: block !important;
}

    .gjs-trt-trait--text .gjs-label-wrp {
        width: 100% !important;
    }


